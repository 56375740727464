
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/index.vue?macro=true";
import { default as indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta } from "/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta?.name ?? "index",
    path: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta?.path ?? "/",
    props: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta?.props ?? false,
    meta: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta || {},
    alias: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta?.alias || [],
    redirect: indexdQwISH5BbM_auigch9Ro2_SVa0e1YS6uWYys36VPqPQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/index.vue")
  },
  {
    name: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta?.name ?? "lang",
    path: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta?.path ?? "/:lang?",
    props: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta?.props ?? false,
    meta: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta || {},
    alias: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta?.alias || [],
    redirect: indexMQdYYBimhMt20Lqrc_kb6UD7E8jDW9Z6JTpbfjwAbI8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta?.name ?? "lang-voucher-start",
    path: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta?.path ?? "/:lang?/voucher/start",
    props: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta?.props ?? false,
    meta: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta || {},
    alias: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta?.alias || [],
    redirect: startdprarQy_e5bV57yE9azDZP_4547l5RcigfNBN6EX0iBVMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta?.name ?? "lang-purchase-start",
    path: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta?.path ?? "/:lang?/purchase/start",
    props: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta?.props ?? false,
    meta: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta || {},
    alias: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta?.alias || [],
    redirect: start9VwFY22hSYM1Un76hfzern0y8G25ZUmbA_45BDqzuujlsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta?.props ?? false,
    meta: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta || {},
    alias: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta?.alias || [],
    redirect: checkoutE4SRONzmRB7vx4zpID0FY3WEfxKvKhWeOYBI6l9EHQkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta?.props ?? false,
    meta: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta || {},
    alias: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta?.alias || [],
    redirect: koHX69kaPn7sKJGOsc3vuMBsw9Ng0OzEl7YTgiRbDUrIAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta?.props ?? false,
    meta: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta || {},
    alias: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta?.alias || [],
    redirect: confirmationQ1E_450yCJy6JHKluiTHJNlVVsZHhsSDtO4_45uZjuX_45b4UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta?.props ?? false,
    meta: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta || {},
    alias: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta?.alias || [],
    redirect: confirmationw8ypqcEMab6c4GlPDcnV91NoFOvXZMtSpF9gZNew8IMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta?.props ?? false,
    meta: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta || {},
    alias: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta?.alias || [],
    redirect: buyer_5dblF4NYnIt61eluGyVXRpaRUGnvJnLIMoy7hGv_u0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta?.props ?? false,
    meta: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta || {},
    alias: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta?.alias || [],
    redirect: buyera1U9bOm7il0oiz3v45jL_45N5zASP2NJ6du7aypbrYxLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta?.props ?? false,
    meta: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta || {},
    alias: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta?.alias || [],
    redirect: paymentSJFvwn6nG7Dkb_45pAdT5rGZEdb9iiqLK5ABxzGl3pPMAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta?.props ?? false,
    meta: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta || {},
    alias: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta?.alias || [],
    redirect: challenge6SFDCQa1Ev71nwKEeI1fLuSwy20J4n3nk4pcoXz1_45uQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta?.props ?? false,
    meta: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta || {},
    alias: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta?.alias || [],
    redirect: payment32Jeq5EN3rpsDVWMv5CEdUULUez9enILGXxwvuHhZZ4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/alicante/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/altea/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/benidorm/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/calpe/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/denia/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/l-alfas-del-pi/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/la-nucia/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/mutxamel/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/rojales/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/alicante/teulada/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/lleida/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/lleida/caldes-de-boi/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/lleida/vallbona-de-les-monges/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/lleida/vielha/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/adeje/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/arona/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/guia-de-isora/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/playa-de-las-americas/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/puerto-de-la-cruz/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/san-cristobal-de-la-laguna/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/san-miguel-de-abona/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/santa-cruz-de-tenerife/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.path ?? "/spa-balneario/tenerife/santa-ursula/",
    props: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.props ?? false,
    meta: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta || {},
    alias: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.alias || [],
    redirect: SpaList_46pageIT9_45Ydk0Hwqoqp1pzIrL_VR6nYByL_45Ci4xo_QI89yYwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/lleida/bruma-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/luxor-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 42",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 43",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 44",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 45",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 46",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 47",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 48",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.name ?? "Spa landing: 49",
    path: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/",
    props: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.props ?? false,
    meta: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta || {},
    alias: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.alias || [],
    redirect: SpaDetail_46pageChfiVsC89cr1D3s4l2N2zW5g6Nd3fGrG3eto9wHJfFsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-ritual-115-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-aromatico-45-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/facial-hyalu-collagem-booster-con-acceso-al-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-hidraforce-source-marine-55min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/serenity-love/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/family-day-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-massage-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-doble/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/gastrospa-lunes-a-viernes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-individual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy-55-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sprunch-con-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/after-work-30-min-masaje-con-circuito-termal-lunes-a-jueves/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/senses-night/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/manicura-y-pedicura-express-con-acceso-a-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/iberostar-vegan-facial-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/brunch-sin-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-de-piernas-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/reflexologia-podal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-craneofacial-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-de-espalda-y-cuello-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-relajante-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-descontracturante-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/masaje-deportivo-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/bruma-spa/circuito-termal-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/chorro-jet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/programa-cuidate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/masaje-cervicodorsal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/facial-express-20-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/programa-mimate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/masaje-completo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/circuito-acuatico-entrada-infantil/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/circuito-acuatico-entrada-s-d-f/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/circuito-acuatico-entrada-l-v/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/programa-rocallaura/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/ritual-marino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/programa-descanso/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/masaje-local/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/masaje-celta-completo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/prenatal-y-posnatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/esencias-y-sentidos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/chocolate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/barro/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-podal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-corporal-ayurveda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/hydraluronic/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-hawaiano/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-adulto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-spa-10-sesiones/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje-doble-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/anthelia-signature-massage-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/oriental-55-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especifico-localizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-almuerzo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-masaje-almuerzo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/spa-en-familia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sprunch-spa-brunch/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/momento-spa-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa-acceso-gimnasio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-relajante-aromaterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/peeling-exfoliante-corporal-piel-de-seda-40mins/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/pack-bienestar-para-dos-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-completo-muscular-profundo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-terapeutico-espalda-cuello-y-hombro/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/drenaje-linfatico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-25/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/piel-de-seda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/reflexologia-podal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/spa-relax-2-horas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/circuito-thalassa-1-hora/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/ritual-vincci-sun/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-40-min-y-spa-2-horas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-20-min-y-spa-2-horas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-celta-de-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-abhyanga/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-local-o-craneo-facial/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-mousse-de-chocolate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/marine-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/albir-relax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-detox/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-aromaterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/vip-senses/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-relajante-general/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-martes-a-viernes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-martes-a-viernes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-geotermal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun-pareja-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-25-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-55-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/de-este-a-oeste/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-anantara/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/tiempo-para-dos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-en-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/oasis-de-serenidad/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-tradicional-tailandes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-muscular-profundo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/dulce-espera/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/facial-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/experiencia-anantara-con-masaje-y-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/viaje-a-traves-de-los-sentidos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa-residente-canario/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/limpieza-profunda-con-hidromass/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/higiene-facial-expres/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-chocolaterapia-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-lirio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-jade/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-relajante-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/exfoliacion-corporal-hidratacion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-orquidea/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-descontracturante-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-loto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-especializado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-ayurveda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-anti-dolor-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-lomi-lomi-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-en-pareja-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-masaje-aromaterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/reflexologia-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-express/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-y-moet-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-muscular-profundo-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-piernas-cansadas-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-craneal-de-la-india/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-pindas-con-extracto-de-cerveza-completo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/ritual-facial-y-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-ritual-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-relax-con-vela-aromatica-de-75-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-beauty-tratamiento-facial-55/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-con-vela-circuito-spa-90min-masaje-relajante-con-vela-aromatica-de-75min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-serenity-circuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-buenos-dias-desayuno-buffet-circuito-spa-90min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-gastro-relax-comida-cena-agua-y-vino-de-la-casa-incl-circuito-spa90min-buffet-o-menu-segun-ocupacion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-renovadora-circuito-spa-de-90min-limpieza-facial-basica-masaje-orient-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-supreme-minimo-2-personas-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-supreme-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-envolvente-circuito-spa-90min-ritual-corporal-con-exfoliacion-y-envolturalimpieza-facial-basica/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-serenitycircuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-min-2-personas-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/yoga-y-meditacion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-spa-de-90min-a-partir-de-15-anos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-holistico-de-piedras-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/hidratante-hialuronico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/firmeza/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/duo-reafirmante-facial-y-corporal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-relajante-aromatico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-de-aguas-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-90/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-spa-privado-para-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena-privado-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/classic-spa-circuito-de-aguas-90/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-en-familia-para-3-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-muscular-profundo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-localizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-canario/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-individual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/tratamiento-facial-antioxidante-de-vitamina-c/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-individual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/chocolaterapia-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje-y-cena/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet-con-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-cena/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-cena-buffet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/circuito-spa-clientes-externos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-jaccuzi-individual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-colageno-marino-ojos-incluidos-natura-bisse/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-lifting-alisa-rellena-tensa-silicium-marino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-cocoon-facial-anti-polucion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-hyaluronico-marino-anti-edad-activador-de-juventud/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/bamboo-scrub-peeling-hidratacion-corporal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-oro-y-aceites-esenciales-relajantes-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-espalda-cuello-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-anticelulitis-masaje-envoltura-anticelulitica/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-terapeutico-deportivo-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-rosa-terapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/vedic-massage-60-min-thai-room/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/futura-mama-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-hammam/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-de-aloe-vera-y-hojas-de-platanera/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/detox-oxigenante-60min-biologique-recherche/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-en-pareja-60-minutos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-relajante-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-day/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-almuerzo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-residente-canario/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-brunch-by-bahia-del-duque/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-energizante-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-adulto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-adulto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-cocktail-en-skybar-adulto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/limpieza-hidratacion-con-acido-hialuronico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia-radiofrecuencia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-para-la-novia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche-para-2-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-de-60-minutos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cavitacion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/envoltura-canaria/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip-para-2-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-persona-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-30-minutos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-respira-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-romantica-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/presoterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/silicium-lift/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/body-sculpt/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personasresidente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personas-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/domingo-familiar-domingos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-0-5-anos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-6-12-anos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/viernes-juvenil/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-vip/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-13-17-anos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cumpleanos-con-picoteo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aqua-baby/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-no-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/experiencia-wellness/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-clasico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-2-dias/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-sales/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-algas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-de-la-polinesia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-esencias/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-indonesio/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-hidratante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lomi-lomi-80m/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aromatico-80m/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/thai-50m/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/piedras-volcanicas-50m/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-oriental/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-canario/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-dragon-de-oro/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/hidroterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-mahana/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-local-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-hindu/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-50min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa-para-2-adultos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-sueco/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/ritual-evasion-de-los-sentidos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-reconfortante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-marruecos-1h-45/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-masaje-aromatico-50-y-piscina-spa-2h/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-aromatico-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-bangalore-80-y-piscina-spa-2h/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-marruecos-105-y-piscina-spa-2h/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-bangalore-1h-20/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-java-45-y-piscina-spa-2h/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-java-45/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-domingos-de-piscina-spa-y-brunch/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-agua-y-relax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-basic/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-premium/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-2h-acceso/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/especial-parejas-2h-piscina-spa-masaje-aromatico-25/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-futura-mama-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-ayurvedico-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/experiencia-romantica-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/ritual-cleopatra-bano-aromatico-masaje-relajante-y-masaje-craneal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-2-adultos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-zen-ceremony/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/masaje-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-exotic-paradise/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/marma-abhyanga-masaje-tradicional-ayurveda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/circuito-termal-con-cava-y-bombones/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-dios-del-atlantico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-en-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/facial-mesoboost/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/facial-hydraluronic/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-paseo-por-los-campos-de-lavanda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-serenity-sanctuary/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/circuito-termal-con-shiroabhyanga-masaje-de-cabeza-hindu/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/exfoliacion-y-masaje-lomi-lomi/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/circuito-termal-con-masaje-relajante-de-aromaterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-h10-atlantic-sunset-horizons-collection/ritual-bloom-sensation/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-thai-oil/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-facial-de-oriente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/drenaje-linfatico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/spa-privado-para-dos-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-deportivo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/pack-spa-experience-para-dos-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-prenatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-geo-serawa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-hawaiano/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two-luxury/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/smooth-operator/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/wrap-remix/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-desayuno/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/inner-peace/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/synchronicity-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/deep-tissue-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/day-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-cena-buffet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/acceso-circuito-spa-cliente-residente/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/its-your-time/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/jelly-gum-massage/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/face-the-music/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-descarga-45min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/hidradermis-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60-min-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-facial/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-45-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-y-templarium/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-ashanti-en-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-en-pareja-masaje-de-30min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/chocolaterapia-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-brunch/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-romantico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-nino-90/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hawaiian-lomi-lomi-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/masaje-infantil/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/abhyanga/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/ritual-de-la-vela-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia-para-dos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-facial-kobido/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/la-utima-experiencia-de-belleza-esse/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-champi/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-abhyanga/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-2-pax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-1-pax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-hidratacion/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/abama-total-wellness/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-bio-energetico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-presion-profunda-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-pureza-marina/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/pura-experiencia-detox/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/ritual-canario-de-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-sandara-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-balines/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/experiencia-ayurvedica/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-balines/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-alojado-2-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-externo-2-personas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-2-adultos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-masaje-relajante-y-cava-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-y-masaje-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/circuito-spa-senda-de-los-sentidos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/limpieza-profunda-tratamiento-contorno-de-ojos-is-clinical/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/despertar-laguna/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suite-royal-therapy/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suenos-mediterraneos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-localizado-20/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-algas-intensive/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-teide-magico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-secreto-de-la-india/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-descontracturante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-cava-y-chocolate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-afrodita/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-personalizado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-ayurveda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-tratamiento-facial-basico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-reflexologia-podal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-el/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-ella/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/oasis-de-la-serenidad-by-alqvimia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-essentially-beautiful-by-alqvimia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-m-v/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/gastro-wellness-experience-cena-spa-mascarilla/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-en-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-brunch-experience-desayuno-buffet-spa-masaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/day-pass-masaje-cocktail/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-30/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-l-v/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-s-d/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-s-d/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-m-v/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-s-d/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/love-secret/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-happy-mum/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-deep-tissue/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa-nino-5-15-anos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-baby-relax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/facial-express/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-maderoterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-air-bio-spa-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-privado-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-oasis-bio-120-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-familiar-60-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-spa-privado/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-familiar-privada-padres-e-hijos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa-luxury/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-belleza-oriental-duo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen-duo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience-duo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-embarazadas-nuad-kon-thong-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-de-bambu-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/envoltura-natural-para-una-piel-perfecta-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-con-aceite-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-4-manos-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pareja-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-wellness/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-pareja-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/manicura-senza/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-pareja/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-reflexologia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-expres/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-calmante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-prenatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-zen-parejas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-con-circuito-termal-120min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/circuito-hydrowellness/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/after-work/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/est-facial-hidratante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-happy-mum/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-deep-tissue/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/body-care-uli-massage/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-baby-relax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/love-secret/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-antiestres/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/antiestres/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-massage/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/cocoon-therapy-envolturas/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/serenity/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/essences-senses/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/booster-vitamin-c/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy-force/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-intense/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/excellence-marine-collagen-lift/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/peeling/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/resugence/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/day-pass-spa-masaje-25min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-ritual/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-reflexologia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-express-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-calmante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-prenatal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-y-masaje-aromaterapia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas-1/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-masaje-y-banera-privada-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-50/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-local-25/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/circuito-termal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/circuito-termal-y-gastronomia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-antiestres-aromatico/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/circuito-termal-y-masaje-25/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/experiencia-relax/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/circuito-termal-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/circuito-termal-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/experiencia-bienestar/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-tailandes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-bajo-ducha-vichy/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-ayurvedico-regenerante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/lleida/balneario-caldes-de-boi/masaje-geotermal/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/circuito-relax-1-entrada/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oxigenacion-facial/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/bamboo-scrub/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/peeling-sales-envoltura-de-chocolate/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-lomi-lomi/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-de-espalda-y-cuello-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-masaje-y-buffet-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-y-buffet-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-relax-para-2/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/diamond-rose-experiencia/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oro-y-aceites-esenciales-relajantes-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/piedras-calientes/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/chocolate-relajante-25-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/ayurveda/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/facial-ritual-of-beauty-30-min/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/banera-hidromasaje/",
    props: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.props ?? false,
    meta: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta || {},
    alias: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.alias || [],
    redirect: ServiceDetail_46pageLkT61cdJC6DILT7e33_zcMWlEpwIk3TPdrh3d73yEZAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-f67c5559-208d-4bf5-a005-c80210859253/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]